<template>
  <div>
    <div class="news-intro-section">
      <intro-page :pageId="currentPageId"></intro-page>
    </div>
    <news-list></news-list>
  </div>
</template>
<script>
import IntroPage from "../components/IntroPage.vue";
import NewsList from '../components/NewsList.vue'
export default {
  name: "news-events",
  components: {
    IntroPage,
    NewsList
  },
  data() {
    return {
      currentPageId:
        "3e189276-9f9f-47c3-8d17-7dbb64199709",
    };
  }
};
</script>
<style lang="scss" scoped>
.news-intro-section{
  margin-top: 150px;
}
@media (max-width: 992px) {
  .news-intro-section {
    margin-top: 100px;
  }
}
@media (max-width: 576px) {
  .news-intro-section {
    margin-top: 0px;
  }
}
</style>