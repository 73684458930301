<template>
<div :class="['post-filter', withBorders ? '': 'no-border']">
  <div class="post-filter--decoration"></div>
  <div class="container">
    <span class="filter-label"><img src="@/assets/filter.svg" alt="filter" >Filter</span>
    <span :class="getFilterClass(term.value)" @click="setFilter(term.value)" v-for="(term, index) of filterValues" :key="index">
      {{term.label}}
    </span>
  </div>
</div>
</template>
<script>
export default {
  data(){
    return{
      filterPost: 'all'
    }
  },
  props:{
    filterValues: Array,
    default: String,
    withBorders: {
      type:Boolean,
      default: true
    },
  },
  methods:{
    setFilter(val){
      this.filterPost = val;
      this.$emit('set-filter', val);
    },
    getFilterClass(val){
      const classes = ['filter-item'];
      if(val === this.filterPost){
        classes.push('active')
      }
      return classes;
    },
  }
}
</script>

<style lang="scss" scoped>
.filter-item{
  cursor: pointer;
  font-weight: 500;
  color: #ACACAC;
  padding: 20px 35px;
  font-size: 17px;
  display: inline-block;
  position: relative;
  &.active{
    color: #293446;
    &::before{
      content: '';
      width: 25px;
      height: 17px;
      position: absolute;
      top:0;
      left: 50%;
      transform: translateX(-50%);
      background: #1cb879;
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;
    }
  }
}
.post-filter{
  position: relative;
  text-align: right;
  overflow: hidden;
  .post-filter--decoration{
    width: 60vw;
    height: 382px;
    border: 1px solid #E9E9E9;
    border-right: 0;
    border-radius: 191px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    opacity: 0.46;
    right: 0%;
    position: absolute;
  }
  &.no-border{
    .post-filter--decoration{
      border:0;
    } 
  }
}
.filter-label{
  font-weight: 200;
  color: #ACACAC;
  font-size: 13px;
  display: inline-flex;
  align-items: center;
  padding-right: 20px;
  img{
    vertical-align: middle;
    margin-right: 5px;
  }
}
@media (min-width: 992px) {

  .post-filter{
    height: 500px;
    margin-bottom: -400px;
  }
}


@media (max-width: 576px){
  .post-filter {
    .container{
      padding: 0;
    }
    .filter-item{
        font-size: 14px;
        padding: 20px 13px;
    }
  }
}
</style>