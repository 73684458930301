<template>
  <div>
    <dynamic-filter
      :filterValues="filterValues"
      default="all"
      @set-filter="setFilter"
    />
    <div>
      <div
        :class="['post-wrapper', post.type]"
        v-for="(post, index) of filtered"
        :key="index"
      >
        <div class="container">
          <div class="row">
            <div class="col-md-4 pr-md-4 p-0">
              <router-link
                  v-if="post.type === 'node--news'"
                  :to="postLink(post,'news')"
                  >
                <div class="post-image-wrapper">
                  <div
                    class="post-image"
                    :style="getStylePostImage(post)"
                  ></div>
                </div>
              </router-link>
              <router-link
                  v-if="post.type === 'node--event'"
                  :to="postLink(post,'event')"
                  >
                <div class="post-image-wrapper">
                  <div
                    class="post-image"
                    :style="getStylePostImage(post)"
                  ></div>
                </div>
              </router-link>
            </div>
            <div class="post-text col-md-8 p-0 d-flex">
              <div class="post-type">
                <span v-if="post.type === 'node--news'"> NEWS </span>
                <span v-else-if="post.type === 'node--event'"> EVENT </span>
              </div>
              <div class="post-content">
                <div>
                  {{ post.date }}
                  <span v-if="post.type === 'node--event'" class="event-type">{{
                    post.attributes.field_event_type
                  }}</span>
                </div>
                <!-- title news-->
                <router-link
                  v-if="post.type === 'node--news'"
                  :to="postLink(post,'news')"
                >
                  <h2>
                    {{ post.attributes.title }}
                  </h2>
                </router-link>
                <!-- title event-->
               <router-link
                  v-else-if="post.type === 'node--event'"
                  :to="postLink(post,'event')"
                >
                  <h2>
                    {{ post.attributes.title }}
                  </h2>
                </router-link>
                <p>
                  {{ post.attributes.field_subtitle }}
                </p>
                <router-link
                  v-if="post.type === 'node--news'"
                  :to="postLink(post,'news')"
                  class="read-more"
                  >Read more</router-link
                >
                <router-link
                  v-else-if="post.type === 'node--event'"
                  :to="postLink(post,'event')"
                  class="read-more"
                  >Read more</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { slugify } from "@/libs/utils";
import DynamicFilter from "@/components/DynamicFilter";
import { fetchNodes } from "@/libs/drupalClient";

export default {
  components: { DynamicFilter },
  data() {
    return {
      newsData: null,
      eventsData: null,
      posts: [],
      defaultPostImage: "",
      filterPost: "all",
      filterValues: [
        {
          label: "ALL",
          value: "all",
        },
        {
          label: "NEWS",
          value: "node--news",
        },
        {
          label: "EVENTS",
          value: "node--event",
        },
      ],
    };
  },
  computed: {
    filtered() {
      if (this.filterPost !== "all") {
        return this.posts.filter((post) => {
          return post.type === this.filterPost;
        });
      } else {
        return this.posts;
      }
    },
  },
  methods: {
    postLink(post, prefix) {
      let postSlug = slugify(post.attributes.title);
      return `/${prefix}/${postSlug}-${post.attributes.drupal_internal__nid}`;
    },
    getStylePostImage(post) {
      let style = {};
      if (post.field_shared_image) {
        style.backgroundImage =
          " url(" +
          process.env.VUE_APP_ENDPOINT +
          post.field_shared_image.attributes.uri.url +
          ")";
      }
      return style;
    },
    formatDate(dateString) {
      return new Date(dateString).toLocaleDateString("en", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
    },
    mergePosts() {
      if (this.newsData && this.eventsData) {
        // unisci array news e eventi e ordina per data
        let posts = this.newsData.concat(this.eventsData).sort((a, b) => {
          return (
            new Date(b.attributes.field_news_date) -
            new Date(a.attributes.field_news_date)
          );
        });
        posts = posts.map((post) => {
          post.date = this.formatDate(post.attributes.field_news_date);
          return post;
        });

        this.posts = posts;
      }
    },
    setFilter(val) {
      this.filterPost = val;
    },
    fetchEvents() {
      return fetchNodes('event',{include:['field_shared_image']})
        .then(nodes=>{
          this.eventsData = nodes;
        });
    },
    fetchNews() {
      return fetchNodes('news',{include:['field_shared_image']})
        .then(nodes=>{
          this.newsData = nodes;
        });
    },
  },
  mounted() {
    Promise.all([
      this.fetchNews(),
      this.fetchEvents()
    ]).then(()=>{
      this.mergePosts();
    });
  },
};
</script>
<style lang="scss" scoped>
.post-image-wrapper {
  max-width: 350px;
  width: 100%;
}
.post-type {
  width: 90px;
  height: 90px;
  font-size: 14px;
  font-weight: bold;
  border: 3px solid #1cb879;
  color: #1cb879;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  margin-right: 30px;
  flex-shrink: 0;
}
.node--news {
  .post-type {
    border-color: #dedcdc;
    color: #666666;
  }
}
.node--event {
  .post-image {
    border: 8px solid #1cb879;
  }
}
.post-image {
  padding-bottom: 100%;
  height: 0;
  background-size: cover;
  background-position: center center;
  max-width: 100%;
  background-image: url("../assets/default-post-image.png");
  img {
    max-width: 100%;
  }
}
img {
  max-width: 100%;
}
p {
  word-break: break-all;
}

a h2 {
  color: #292d34;
  margin: 1.8rem 0;
}

.event-type {
  font-weight: 800;
  font-size: 20px;
  color: #acacac;
  padding-left: 16px;
  margin-left: 12px;
  border-left: 2px solid;
  text-transform: capitalize;
}
@media (min-width: 992px) {
  .post-wrapper {
    padding: 100px 200px;
    margin-left: 20px;
    .container {
    }
    &:nth-child(even) {
      background-color: #fafafa;
      border-top-left-radius: 300px;
      border-bottom-left-radius: 300px;
    }
  }
}

@media (max-width: 992px) {
  a h2 {
    margin: 1rem 0;
  }
  .post-content h2 {
    font-size: 1.8rem;
    line-height: 2.2rem;
    margin-bottom: 2rem;
  }
  .post-wrapper {
    margin: 0rem 0rem 5rem 0rem;
  }
}
@media (max-width: 768px) {
  .post-image-wrapper {
    height: auto;
    max-height: 400px;
    padding-right: 0;
    max-width: none;
    margin-bottom: 1.2rem;
  }
  .post-image {
    padding: 0;
    width: 400px;
    height: 400px;
    margin: 0px auto;
  }
}
@media (max-width: 576px) {
    .post-image-wrapper {
    max-height: 300px;
  }
  .post-image {
    width: 300px;
    height: 300px;
  }
  .post-text {
    flex-direction: column;
  }
  .post-type {
    margin-bottom: 1rem;
    width: 74px;
    height: 59px;
    margin-right: 19px;
    .event-type {
      font-weight: 500;
      font-size: 17px;
    }
  }
  .post-content h2 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .post-wrapper {
    margin: 0rem 0rem 1rem 0rem;
  }
}
</style>